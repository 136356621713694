<svg (click)="clickHandler()" [ngClass]="{'menu': !inMenu}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60" height="60"
  viewBox="0 0 60 60">
  <defs>
    <filter id="crcle" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha" />
      <feGaussianBlur stdDeviation="3" result="blur" />
      <feFlood flood-opacity="0.161" />
      <feComposite operator="in" in2="blur" />
      <feComposite in="SourceGraphic" />
    </filter>
  </defs>
  <g>
    <g class="cls-3">
      <circle id="crcle" cx="30" cy="30" r="21"/>
    </g>
    <path class="a" d="M24 24 L 36 36"/>   
    <path class="a" d="M24 36 L 36 24"/>
    <path class="b" d="M22 24 H 38"/>
    <path class="b" d="M22 30 H 38"/>
    <path class="b" d="M22 36 H 38"/>
  </g>
</svg>