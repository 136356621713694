import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-closed',
  templateUrl: './about-closed.component.html',
  styleUrls: ['./about-closed.component.css']
})
export class AboutClosedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
