import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-leftscroll',
  templateUrl: './leftscroll.component.html',
  styleUrls: ['./leftscroll.component.css']
})
export class LeftscrollComponent implements OnInit {

  constructor() { }

  @Input()
  left: boolean = true;

  @Input()
  right: boolean = false;

  ngOnInit(): void {
  }

}
