<div class="wrapper">
  <svg xmlns="http://www.w3.org/2000/svg" width="269.888" height="88.893" viewBox="0 0 269.888 88.893">
    <g id="Group_206" data-name="Group 206" transform="translate(-1285.35 -228.959)">
      <g id="Group_199" data-name="Group 199" transform="translate(421.35 -316.034)">
        <g id="Group_198" data-name="Group 198" transform="translate(611.314 -65.635)">
          <g id="Group_194" data-name="Group 194">
            <path id="Path_225" data-name="Path 225" class="cls-1"
              d="M261.9,630.817c-4.536,0-8.213-4.2-8.213-9.388s3.677-9.387,8.213-9.387" />
            <path id="Path_226" data-name="Path 226" class="cls-1"
              d="M266.428,631.392c4.814,0,8.717,4.46,8.717,9.962s-3.9,9.962-8.717,9.962" />
          </g>
          <g id="Group_196" data-name="Group 196">
            <g id="Group_195" data-name="Group 195">
              <path id="Path_227" data-name="Path 227" class="cls-1"
                d="M310.9,645.9a17.342,17.342,0,0,1-24.642-21.007" />
              <path id="Path_228" data-name="Path 228" class="cls-1"
                d="M293.715,615.581a17.339,17.339,0,0,1,24.638,21.47" />
            </g>
            <line id="Line_134" data-name="Line 134" class="cls-1" x2="28.081" y2="24.035"
              transform="translate(288.068 618.928)" />
          </g>
          <path id="Path_229" data-name="Path 229" class="cls-1" d="M352.547,646.987a18,18,0,1,1,12.369-22.747" />
          <line id="Line_135" data-name="Line 135" class="cls-1" y2="27.009" transform="translate(378.736 611.722)" />
          <path id="Path_230" data-name="Path 230" class="cls-1" d="M387,648.082l12.735-34.443,12.735,34.367" />
          <g id="Group_197" data-name="Group 197">
            <line id="Line_136" data-name="Line 136" class="cls-1" x2="18" transform="translate(422.986 647.976)" />
            <path id="Path_231" data-name="Path 231" class="cls-1" d="M422.986,647.976" />
            <line id="Line_137" data-name="Line 137" class="cls-1" y2="30.425" transform="translate(422.986 611.976)" />
          </g>
        </g>
      </g>
      <g id="Group_201" data-name="Group 201" transform="translate(1385 -403.737)">
        <path id="Path_232" data-name="Path 232" class="cls-1" d="M55.972,695.028,66.5,684.5V720" />
        <path id="Path_233" data-name="Path 233" class="cls-1" d="M44.406,685.406,51.5,692.5l6.972-6.972" />
        <path id="Path_234" data-name="Path 234" class="cls-1" d="M37,720V685l10.406,10.6" />
        <g id="Group_200" data-name="Group 200">
          <line id="Line_138" data-name="Line 138" class="cls-1" x1="22.217" transform="translate(76.467 685.826)" />
          <line id="Line_139" data-name="Line 139" class="cls-1" x2="22.217" transform="translate(76.448 703.775)" />
          <line id="Line_140" data-name="Line 140" class="cls-1" x2="22.217" transform="translate(76.464 720.589)" />
        </g>
        <path id="Path_235" data-name="Path 235" class="cls-1"
          d="M107.816,684.339c8.635,0,15.635,8,15.635,17.869s-7,17.868-15.635,17.868" />
        <line id="Line_141" data-name="Line 141" class="cls-1" y2="27.009" transform="translate(134.566 683.703)" />
        <path id="Path_236" data-name="Path 236" class="cls-1" d="M143.831,721.063l12.735-34.443L169.3,720.987" />
      </g>
    </g>
  </svg>

  <a href="https://facebook.com/vigyazopeter" id="fb">
    <img src="/assets/f_logo_RGB-White_100-image.png" />
  </a>
</div>