<svg xmlns="http://www.w3.org/2000/svg" width="191.521" height="34.603" viewBox="0 0 191.521 34.603">
    <g id="Group_188" data-name="Group 188" transform="translate(-287.163 -170.342)">
      <g id="Group_187" data-name="Group 187" transform="translate(288.168 172.468)">
        <path id="Path_203" data-name="Path 203" class="cls-1" d="M251.967,556.944" transform="translate(-224.076 -542.773)"/>
        <path id="Path_204" data-name="Path 204" class="cls-1" d="M235.152,567.662a14.348,14.348,0,1,1,9.857-18.13" transform="translate(-216.972 -539.229)"/>
        <g id="Group_185" data-name="Group 185" transform="translate(36.012 2.765)">
          <g id="Group_184" data-name="Group 184">
            <path id="Path_205" data-name="Path 205" class="cls-1" d="M282.585,570.918a13.82,13.82,0,0,1-19.639-16.743" transform="translate(-262.156 -544.976)"/>
            <path id="Path_206" data-name="Path 206" class="cls-1" d="M270.6,544.413a13.82,13.82,0,0,1,19.637,17.112" transform="translate(-263.871 -542.633)"/>
          </g>
          <line id="Line_130" data-name="Line 130" class="cls-1" x2="22.381" y2="19.156" transform="translate(2.232 4.447)"/>
        </g>
        <path id="Path_207" data-name="Path 207" class="cls-1" d="M323.6,554.835" transform="translate(-238.617 -542.345)"/>
        <g id="Group_186" data-name="Group 186" transform="translate(71.438 0.934)">
          <path id="Path_208" data-name="Path 208" class="cls-1" d="M306.6,569.028V540.335l11.955,16.339" transform="translate(-306.604 -540.335)"/>
          <path id="Path_209" data-name="Path 209" class="cls-1" d="M314.6,555.08l11.158,13.948V540.335" transform="translate(-308.228 -540.335)"/>
        </g>
        <path id="Path_210" data-name="Path 210" class="cls-1" d="M340.189,570.322V541.37h12.218" transform="translate(-241.983 -539.612)"/>
        <path id="Path_211" data-name="Path 211" class="cls-1" d="M360.36,569.92l10.15-27.452,10.15,27.391" transform="translate(-246.078 -539.835)"/>
        <path id="Path_212" data-name="Path 212" class="cls-1" d="M428.619,558.622" transform="translate(-259.934 -543.114)"/>
        <path id="Path_213" data-name="Path 213" class="cls-1" d="M411.8,567.339a14.347,14.347,0,1,1,9.858-18.13" transform="translate(-252.83 -539.164)"/>
        <path id="Path_214" data-name="Path 214" class="cls-1" d="M440.679,569.1V540.144H452.9" transform="translate(-262.382 -539.363)"/>
      </g>
    </g>
  </svg>
  