import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbtn',
  templateUrl: './navbtn.component.html',
  styleUrls: ['./navbtn.component.css']
})
export class NavbtnComponent implements OnInit {

  constructor(private router: Router) { }

  public inMenu: boolean = false;

  clickHandler()
  {
    console.log(this.inMenu);
    this.inMenu = !this.inMenu;
    if(this.inMenu)
      this.router.navigate(['/menu']);
    else
      this.router.navigate(['/']);
  } 
  ngOnInit(): void {
  }

}
