<div class="spacer-wrapper">
    <div class="about">
      <app-about-component></app-about-component>
    </div>
    <div class="contact">
      <app-contact></app-contact>
    </div>
    <div class="soc">
      <app-social></app-social>
    </div>
  </div>