import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbtnComponent } from './components/nav/navbtn/navbtn.component';
import { MainbtnComponent } from './components/nav/mainbtn/mainbtn.component';
import { MainComponent } from './components/menu/main/main.component';
import { AboutComponentComponent } from './components/menu/about-component/about-component.component';
import { ContactComponent } from './components/menu/contact/contact.component';
import { SocialComponent } from './components/menu/social/social.component';
import { AboutClosedComponent } from './components/menu/about/about-closed/about-closed.component';
import { ContactClosedComponent } from './components/menu/contact/contact-closed/contact-closed.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ImagePresenterComponent } from './components/home/image-presenter/image-presenter.component';
import { HomeScrollDirective } from './directives/home-scroll.directive';
import { LeftscrollComponent } from './components/home/leftscroll/leftscroll.component';
import { RightscrollComponent } from './components/home/rightscroll/rightscroll.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbtnComponent,
    MainbtnComponent,
    MainComponent,
    AboutComponentComponent,
    ContactComponent,
    SocialComponent,
    AboutClosedComponent,
    ContactClosedComponent,
    FooterComponent,
    HomeComponent,
    ImagePresenterComponent,
    HomeScrollDirective,
    LeftscrollComponent,
    RightscrollComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
