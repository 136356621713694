<svg xmlns="http://www.w3.org/2000/svg" width="165" height="25" viewBox="0 0 165 25">
    <g transform="translate(-878 -1038)">
      <g class="cls-1" transform="translate(878 1038)">
        <rect class="cls-4" width="165" height="25" rx="12.5"/>
        <rect class="cls-5" x="0.5" y="0.5" width="164" height="24" rx="12"/>
      </g>
      <g id="Group_120" data-name="Group 120" transform="translate(295.652 481.897)">
        <g id="Group_119" data-name="Group 119" transform="translate(597.999 563)">
          <g id="Group_107" data-name="Group 107" transform="translate(0 1.686)">
            <path class="cls-2" d="M9.693,253.53a2.71,2.71,0,1,1,.147-3.794" transform="translate(-3.409 -247.131)"/>
            <path  class="cls-3" d="M1.866,261.138a4.39,4.39,0,0,1-2.111.538,4.408,4.408,0,0,1-4.408-4.408,4.4,4.4,0,0,1,.252-1.473" transform="translate(4.653 -252.86)"/>
            <path  class="cls-3" d="M7.594,239.63a4.41,4.41,0,0,1,6.267,5.461" transform="translate(-5.446 -239.062)"/>
            <line  class="cls-3" x2="0.602" y2="0.576" transform="translate(0.773 1.396)"/>
            <line  class="cls-3" x2="0.556" y2="0.556" transform="translate(7.327 7.327)"/>
          </g>
          <path data-name="Path 105" class="cls-3" d="M171.434,263" transform="translate(-140.543 -257.113)"/>
          <path data-name="Path 106" class="cls-3" d="M67.339,241.47l1.465,3.516L72.84,236" transform="translate(-54.709 -234.852)"/>
          <line class="cls-3" y2="5.306" transform="translate(20.214 1.15)"/>
          <g transform="translate(22.807 1.638)">
            <path id="Path_107" data-name="Path 107" class="cls-3" d="M133.439,243.032a4.047,4.047,0,1,1-4.042-4.248" transform="translate(-125.355 -238.784)"/>
            <path id="Path_108" data-name="Path 108" class="cls-3" d="M148.4,238.784q.084,0,.167,0" transform="translate(-144.353 -238.784)"/>
            <line id="Line_72" data-name="Line 72" class="cls-3" y1="0.002" x2="2.799" transform="translate(5.492 4.535)"/>
          </g>
          <path class="cls-3" d="M185.459,234.888,181.64,240.1v3.776" transform="translate(-148.958 -233.934)"/>
          <path class="cls-3" d="M202.916,245.86l3.233-8.745,3.233,8.726" transform="translate(-166.503 -235.77)"/>
          <line class="cls-3" x2="0.764" y2="1.464" transform="translate(40.177 0)"/>
          <path class="cls-3" d="M254.96,236.288l-5.628-.041,5.711,8.442h-5.862" transform="translate(-204.651 -235.054)"/>
          <g id="Group_110" data-name="Group 110" transform="translate(51.946 1.258)">
            <g id="Group_109" data-name="Group 109">
              <path id="Path_112" data-name="Path 112" class="cls-3" d="M297.975,258.7a4.41,4.41,0,0,1-6.267-5.343" transform="translate(-291.456 -250.418)"/>
              <path id="Path_113" data-name="Path 113" class="cls-3" d="M303.7,237.188a4.41,4.41,0,0,1,6.267,5.461" transform="translate(-301.555 -236.62)"/>
            </g>
            <line id="Line_74" data-name="Line 74" class="cls-3" x2="7.142" y2="6.113" transform="translate(0.712 1.419)"/>
          </g>
          <line id="Line_75" data-name="Line 75" class="cls-3" x1="1.221" y1="1.111" transform="translate(59.381 0.13)"/>
          <g id="Group_111" data-name="Group 111" transform="translate(66.956 0.903)">
            <line class="cls-3" y2="9.309" transform="translate(0.001 0.39)"/>
            <path class="cls-3" d="M377.025,237.847a3.493,3.493,0,0,0,.337,1.359,3.275,3.275,0,0,0,4.442,1.565,3.471,3.471,0,0,0,1.518-4.579,3.355,3.355,0,0,0-1.581-1.6" transform="translate(-377.024 -234.596)"/>
            <path class="cls-3" d="M377.018,253.9" transform="translate(-377.018 -250.514)"/>
          </g>
          <g id="Group_112" data-name="Group 112" transform="translate(86.999 0.967)">
            <line class="cls-3" x1="5.811" transform="translate(0.005)"/>
            <line class="cls-3" x2="5.811" transform="translate(0 4.694)"/>
            <line class="cls-3" x2="5.811" transform="translate(0.004 9.092)"/>
          </g>
          <path class="cls-3" d="M536.349,242.662l-2.82-2.617a6.991,6.991,0,0,0,2.34-2.307,3.411,3.411,0,0,0-5.35-4.032" transform="translate(-436.635 -232.233)"/>
          <path class="cls-3" d="M588.339,283.119" transform="translate(-484.311 -273.704)"/>
          <path class="cls-3" d="M460.588,244.721v-9.462h3.993" transform="translate(-378.971 -234.24)"/>
          <g id="Group_113" data-name="Group 113" transform="translate(107.369 0.693)">
            <path id="Path_119" data-name="Path 119" class="cls-3" d="M620.855,233.4a2.747,2.747,0,0,1,0,5.446" transform="translate(-618.491 -233.401)"/>
            <path id="Path_120" data-name="Path 120" class="cls-3" d="M607.958,268.943a2.95,2.95,0,0,1-.576-1.775,2.57,2.57,0,0,1,2.383-2.723" transform="translate(-607.382 -258.999)"/>
            <line id="Line_80" data-name="Line 80" class="cls-3" x2="1.362" transform="translate(2.343 9.902)"/>
          </g>
          <g id="Group_114" data-name="Group 114" transform="translate(123.036 1.261)">
            <path id="Path_121" data-name="Path 121" class="cls-3" d="M710.161,236.635a2.747,2.747,0,0,1,0,5.446" transform="translate(-707.797 -236.635)"/>
            <path id="Path_122" data-name="Path 122" class="cls-3" d="M697.264,272.177a2.95,2.95,0,0,1-.576-1.775,2.57,2.57,0,0,1,2.383-2.723" transform="translate(-696.688 -262.233)"/>
            <line id="Line_81" data-name="Line 81" class="cls-3" x2="1.362" transform="translate(2.343 9.902)"/>
          </g>
          <path id="Path_123" data-name="Path 123" class="cls-3" d="M675.336,237.427" transform="translate(-556.046 -236.027)"/>
          <g id="Group_115" data-name="Group 115" transform="translate(114.001 1.415)">
            <path id="Path_124" data-name="Path 124" class="cls-3" d="M647.911,246.865a5.378,5.378,0,0,1-.009-9.348" transform="translate(-645.188 -237.517)"/>
            <path id="Path_125" data-name="Path 125" class="cls-3" d="M675.494,237.779a5.631,5.631,0,0,1-.067,9.388" transform="translate(-670.122 -237.733)"/>
          </g>
          <g id="Group_116" data-name="Group 116" transform="translate(129.267 1.044)">
            <line class="cls-3" x2="0.784" transform="translate(0 0.268)"/>
            <line class="cls-3" y2="10.067" transform="translate(1.733)"/>
            <line class="cls-3" x2="0.763" transform="translate(2.666 9.804)"/>
          </g>
          <g transform="translate(74.559 0.108)">
            <g transform="translate(0 0.944)">
              <line class="cls-3" x1="5.772" transform="translate(0.005)"/>
              <line class="cls-3" x2="5.772" transform="translate(0 4.663)"/>
              <line class="cls-3" x2="5.772" transform="translate(0.004 9.032)"/>
            </g>
            <line class="cls-3" x2="1.107" transform="translate(2.262)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>  
  