<a href="/">
  <svg xmlns="http://www.w3.org/2000/svg" width="249.807" height="32.092" viewBox="0 0 249.807 32.092">
    <g id="Group_216" data-name="Group 216" transform="translate(-685.077 -726.308)">
      <g id="Group_214" data-name="Group 214" transform="translate(686 727)">
        <path id="Path_237" data-name="Path 237" class="cls-1" d="M171.434,263" transform="translate(-119.386 -246.224)"/>
        <path id="Path_238" data-name="Path 238" class="cls-1" d="M67.339,251.588l4.175,10.02L83.018,236" transform="translate(-67.339 -232.725)"/>
        <line id="Line_144" data-name="Line 144" class="cls-1" y2="15.123" transform="translate(21.617 3.277)"/>
        <g id="Group_207" data-name="Group 207" transform="translate(29.008 4.668)">
          <path id="Path_239" data-name="Path 239" class="cls-1" d="M148.395,250.891c0,6.687-5.158,12.107-11.52,12.107s-11.52-5.421-11.52-12.107,5.157-12.107,11.52-12.107" transform="translate(-125.355 -238.784)"/>
          <path id="Path_240" data-name="Path 240" class="cls-1" d="M148.4,238.784q.238,0,.475.01" transform="translate(-136.875 -238.784)"/>
          <line id="Line_145" data-name="Line 145" class="cls-1" y1="0.006" x2="7.979" transform="translate(15.652 12.925)"/>
        </g>
        <path id="Path_241" data-name="Path 241" class="cls-1" d="M192.525,234.888,181.64,249.733v10.762" transform="translate(-124.489 -232.168)"/>
        <path id="Path_242" data-name="Path 242" class="cls-1" d="M202.916,262.041l9.216-24.926,9.216,24.871" transform="translate(-135.128 -233.282)"/>
        <line id="Line_146" data-name="Line 146" class="cls-1" x2="2.177" y2="4.172" transform="translate(78.514)"/>
        <path id="Path_243" data-name="Path 243" class="cls-1" d="M250.224,236.365l15.254-.118L250,260.308h15.889" transform="translate(-158.669 -232.848)"/>
        <g id="Group_209" data-name="Group 209" transform="translate(112.059 3.586)">
          <g id="Group_208" data-name="Group 208">
            <path id="Path_244" data-name="Path 244" class="cls-1" d="M310.036,268.582a12.57,12.57,0,0,1-17.862-15.228" transform="translate(-291.456 -244.987)"/>
            <path id="Path_245" data-name="Path 245" class="cls-1" d="M303.7,238.239A12.569,12.569,0,0,1,321.564,253.8" transform="translate(-297.579 -236.62)"/>
          </g>
          <line id="Line_147" data-name="Line 147" class="cls-1" x2="20.356" y2="17.423" transform="translate(2.03 4.045)"/>
        </g>
        <line id="Line_148" data-name="Line 148" class="cls-1" x1="3.481" y1="3.166" transform="translate(133.249 0.371)"/>
        <g id="Group_210" data-name="Group 210" transform="translate(154.84 2.574)">
          <line id="Line_149" data-name="Line 149" class="cls-1" y2="26.533" transform="translate(0.004 1.11)"/>
          <path id="Path_246" data-name="Path 246" class="cls-1" d="M377.025,243.861a9.954,9.954,0,0,0,.961,3.874,9.333,9.333,0,0,0,12.66,4.461,9.892,9.892,0,0,0,4.326-13.052,9.563,9.563,0,0,0-4.505-4.549" transform="translate(-377.021 -234.596)"/>
          <path id="Path_247" data-name="Path 247" class="cls-1" d="M377.018,253.9" transform="translate(-377.018 -244.248)"/>
        </g>
        <g id="Group_211" data-name="Group 211" transform="translate(211.963 2.756)">
          <line id="Line_150" data-name="Line 150" class="cls-1" x1="16.561" transform="translate(0.015)"/>
          <line id="Line_151" data-name="Line 151" class="cls-1" x2="16.561" transform="translate(0 13.38)"/>
          <line id="Line_152" data-name="Line 152" class="cls-1" x2="16.561" transform="translate(0.012 25.914)"/>
        </g>
        <path id="Path_248" data-name="Path 248" class="cls-1" d="M547.134,260.861,539.1,253.4a19.926,19.926,0,0,0,6.67-6.574,9.721,9.721,0,0,0-15.247-11.491" transform="translate(-298.929 -231.137)"/>
        <path id="Path_249" data-name="Path 249" class="cls-1" d="M460.588,262.226V235.259h11.381" transform="translate(-263.963 -232.354)"/>
        <g id="Group_213" data-name="Group 213" transform="translate(176.509 0.308)">
          <g id="Group_212" data-name="Group 212" transform="translate(0 2.69)">
            <line id="Line_153" data-name="Line 153" class="cls-1" x1="16.451" transform="translate(0.015)"/>
            <line id="Line_154" data-name="Line 154" class="cls-1" x2="16.451" transform="translate(0 13.291)"/>
            <line id="Line_155" data-name="Line 155" class="cls-1" x2="16.451" transform="translate(0.012 25.742)"/>
          </g>
          <line id="Line_156" data-name="Line 156" class="cls-1" x2="3.154" transform="translate(6.446)"/>
        </g>
      </g>
    </g>
  </svg>
</a>
