<div class="home-main">
  <div class="slide" [style.margin-left.px]="-this.inverse_current" #slide>
    <app-image-presenter *ngFor="let item of this.items" [imgData]="item"
    [left]="((item.index+2 - (this.inverse_current/this.actualWidth))*0.25)*(this.imgWidth - this.actualWidth)"
    [owner]="this"></app-image-presenter>
  </div>
  <div id="rightscroll-wrapper" (click)="this.slideOneRight()">
    <app-rightscroll [left]="this.position <= 0" [right]="this.position >= this.pos_right" ></app-rightscroll>

  </div>
  <div id="leftscroll-wrapper" (click)="this.slideOneLeft()">
    <app-leftscroll [left]="this.position <= 0"></app-leftscroll>
  </div>
</div>