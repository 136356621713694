import { AfterViewInit, Component, ComponentRef, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { slideInAnimation } from 'src/app/animations';
import { ImagePresenterComponent } from './image-presenter/image-presenter.component';


export interface PresenterElem{
  id: string,
  text: string,
  imgUrl: string,
  index: number,
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [ slideInAnimation]

})

export class HomeComponent implements OnInit {

  public windowWidth: number = 0;
  public windowHeight: number = 0;
  public imgPerScreen: number = 3
  private speed: number = 20;
  private deltaVal: number = 100;

  constructor() { 
    this.resetValues(window.innerWidth, window.innerHeight);
  }

  imgWidth : number = 0;
  actualWidth: number = 0;
  getImgWidth() : number{
    return this.windowHeight * 0.7406987295825771;
  }
  getActualWidth() : number{
    return this.windowWidth / this.imgPerScreen;
  }

  public handlers: Array<ImagePresenterComponent> = [];
  public items: Array<PresenterElem> =
  [
    {id: "elso", text: "Kattints ram", imgUrl: "0.jpg", index: 0},
    {id: "elso", text: "Kattints ram", imgUrl: "1.jpg", index: 1},
    {id: "elso", text: "Kattints ram", imgUrl: "2.jpg", index: 2},
    {id: "elso", text: "Kattints ram", imgUrl: "3.jpg", index: 3},
    {id: "elso", text: "Kattints ram", imgUrl: "4.jpg", index: 4},
    {id: "elso", text: "Kattints ram", imgUrl: "5.jpg", index: 5}
  ]
  
  public position: number = 0;
  public pos_right: number = 0;
  public inverse_current: number = 0;

  isScrolling: boolean = false;

  resetValues(windowWidth: number, windowHeight:number){
    this.windowWidth = windowWidth;
    this.windowHeight = windowHeight;
    this.pos_right = (this.windowWidth/this.imgPerScreen)*this.items.length - this.windowWidth;
    this.imgWidth = this.getImgWidth();
    this.actualWidth = this.getActualWidth();
  }

  feq(lv : number, rv : number): boolean{
    return Math.abs(lv-rv) <= 0.001;
  }

  private normalizePos(): void{
    //normalize
    if(this.position < 0)
      this.position = 0;
    if(this.position > this.pos_right)
      this.position = this.pos_right;
  }

  @HostListener("wheel", ["$event"])
  onWindowScroll(event: any)
  {
    console.log("Scroll Event", this.position);

    if((event.deltaY < 0 && this.position > 0) || event.deltaY > 0 && this.position < this.pos_right)
      this.position += event.deltaY >= 0 ? this.deltaVal : -this.deltaVal;

    this.normalizePos();

    if(!this.isScrolling){
      this.isScrolling = true;
      this.scrollSlide();
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resetValues(event.target.innerWidth, event.target.innerHeight);
  }

  calcDelta(current: number): number{    
     let tval = (this.position - current) / this.speed;
     return tval;
  }
  updateChildren(){
    this.handlers.forEach((h, i) => {
      let diff: number = this.imgWidth - this.actualWidth;
      let val: number = this.inverse_current/this.actualWidth;
      h.vxOnScroll(((i+2 - (this.inverse_current/this.actualWidth))*0.25)*(this.imgWidth - this.actualWidth));
    });
  }
  scrollSlide(){
    this.inverse_current += this.calcDelta(this.inverse_current);
    if(this.feq(this.inverse_current,this.position))      
      this.isScrolling = false;    
    else{
      window.requestAnimationFrame(() => this.scrollSlide());
    }
  }
  getItemWidth() :number{
    return this.windowWidth/this.imgPerScreen;
  }
  slideOneLeft(){
    this.items.forEach(p=>{
      if(p.index*  this.getItemWidth() < this.inverse_current)
      {
        this.position = p.index *this.getItemWidth();
        this.isScrolling = true;
        this.scrollSlide();
      }
    })
  }
  slideOneRight(){
    console.log(this.inverse_current);
    for(let i = 0; i < this.items.length; i++ ){
      if((i+3)*this.getItemWidth() > this.inverse_current)
      {
        this.position = (i-2) *this.getItemWidth();
        this.isScrolling = true;
        this.scrollSlide();
      }
    }
  }
  ngOnInit(): void {
  }
}
