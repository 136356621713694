import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { HomeComponent, PresenterElem } from '../home.component';

@Component({
  selector: 'app-image-presenter',
  templateUrl: './image-presenter.component.html',
  styleUrls: ['./image-presenter.component.css']
})
export class ImagePresenterComponent implements OnInit {

  @Input() 
  owner: HomeComponent | undefined;
  @Input()
  left: number = 0;
  @Input()
  imgData: PresenterElem = {id: "asdf", text: "err", imgUrl:"err.jpg", index: 42};

  cWidth: string = '33.33333vw';
  imgpos: number = -1;
  inverse_left: number = 0;
  constructor() { 
  }
  /*takes a number between 0 and itemc/3*/
  calculateDesiredPos(pos: number): number{
    if(this.owner){
      let base = this.imgData.index * (this.owner.windowWidth/3);
      base -= pos*(this.owner.windowWidth/3);
      return base;
    }
    return 0;
  }
  vxOnScroll(currentVal: number): void{
    this.inverse_left = currentVal;
  }

  ngOnInit(): void {
  }
  ngOnDestroy(){
  }

}
