import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appHomeScroll]'
})
export class HomeScrollDirective {

  constructor(private elementRef : ElementRef) { }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(_event: any)
  {
    this.elementRef.nativeElement.style.top = 42;
  }
}
