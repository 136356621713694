<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 158.738 39.753" preserveAspectRatio="xMidYMid meet">
    <g id="Group_193" data-name="Group 193" transform="translate(-881.062 -521.079)">
        <g id="Group_192" data-name="Group 192" transform="translate(844.848 -89.509)">
            <path id="Path_215" data-name="Path 215" class="cls-1"
                d="M37.152,647.912l12.735-34.443,12.735,34.367" />
            <path id="Path_216" data-name="Path 216" class="cls-1"
                d="M72.941,633.487a12.915,12.915,0,0,0,4.436,2.674,12.111,12.111,0,0,0,15.708-7.523,12.835,12.835,0,0,0-8-15.945,12.4,12.4,0,0,0-8.307-.04" />
            <path id="Path_217" data-name="Path 217" class="cls-1" d="M73,624.871" />
            <g id="Group_190" data-name="Group 190">
                <g id="Group_189" data-name="Group 189">
                    <path id="Path_218" data-name="Path 218" class="cls-1"
                        d="M132.522,645.123a17.341,17.341,0,0,1-24.641-21.007" />
                    <path id="Path_219" data-name="Path 219" class="cls-1"
                        d="M115.338,614.808a17.339,17.339,0,0,1,24.638,21.469" />
                </g>
                <line id="Line_131" data-name="Line 131" class="cls-1" x2="28.081" y2="24.035"
                    transform="translate(109.691 618.154)" />
            </g>
            <g id="Group_191" data-name="Group 191">
                <path id="Path_220" data-name="Path 220" class="cls-1"
                    d="M169.871,639.085c0,4.814-4.46,8.717-9.962,8.717s-9.963-3.9-9.963-8.717" />
                <line id="Line_132" data-name="Line 132" class="cls-1" y2="23.5"
                    transform="translate(170.129 612.009)" />
                <path id="Path_221" data-name="Path 221" class="cls-1" d="M149.629,611.509" />
                <line id="Line_133" data-name="Line 133" class="cls-1" y2="23.5"
                    transform="translate(149.997 611.863)" />
            </g>
            <path id="Path_222" data-name="Path 222" class="cls-1" d="M179.623,648.318V611.993h15.33" />
            <path id="Path_223" data-name="Path 223" class="cls-1" d="M86.781,625.192" />
            <path id="Path_224" data-name="Path 224" class="cls-1"
                d="M72.287,644.909a12.03,12.03,0,0,0,14.726,3.121,12.836,12.836,0,0,0,5.614-16.935q-.2-.429-.434-.833" />
        </g>
    </g>
</svg>